@import ../variables


.blockFAQ
  flex-direction: column
  align-items: center
  margin-top: 100px
  margin-bottom: 100px

  &__title
    font-size: 28px
    text-align: left
    font-weight: 500
    margin-bottom: 30px

  &__subtitle
    font-size: 16px
    color: $color-text
    margin-bottom: 50px
    font-weight: 500

    a
      color: $color-primary
      text-decoration: none
      font-weight: 600

  &__items
    flex-direction: row
    flex-wrap: wrap
    margin-top: 40px

  &__item
    flex-direction: column
    max-width: 33%
    padding: 0 20px
    padding-bottom: 40px

  &__itemTitle
    font-size: 16px
    font-weight: 700
    margin-bottom: 20px
    color: $color-secondary
  
  &__itemBody
    font-size: 15px
    font-weight: 400
    color: $color-text
    line-height: 1.65em

  @media (max-width: $layout-breakpoint-small)
    &__items
      flex-direction: column

    &__item
      max-width: unset