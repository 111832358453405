@import ./variables

.post
  margin: 100px
  flex-direction: column

  &__image
    width: 100%
    height: 400px
    margin-bottom: 50px

  &__title
    font-size: 36px
    text-align: left
    line-height: 1.25em
    color: $color-secondary
    font-weight: 700
    text-align: center
    margin-bottom: 20px

  &__meta
    text-align: center
    margin-bottom: 50px
    font-style: italic
    color: $color-text

    b
      font-weight: 600
      color: $color-secondary
      margin: 0 5px
      font-style: normal
      font-size: 16px

  &__body
    flex-direction: column

    p
      font-size: 18px
      line-height: 1.75em
      color: $color-text
      margin-bottom: 20px

    a
      color: $color-primary
      text-decoration: none

@media (max-width: $layout-breakpoint-small)
  .post
    margin: 50px

    &__title
      font-size: 30px

    &__meta
      font-size: 14px

    &__body
      p
        font-size: 16px