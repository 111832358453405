@import ../variables
  
.blockContact
  flex-direction: row
  display: flex
  padding: 0 20px
  margin-top: 100px
  margin-bottom: 100px

  &__title
    font-size: 30px
    font-weight: 700
    color: $color-secondary
    margin-bottom: 20px

  &__subtitle
    font-size: 40px
    font-weight: 700
    color: $color-secondary
    margin-bottom: 20px
    margin-top: 40px
    white-space: pre-wrap

  &__body
    color: $color-text
    margin-bottom: 75px
    margin-top: 20px

  &__contactRows
    flex-direction: column
    display: flex

  &__contactRow
    flex-direction: row
    display: flex
    margin-bottom: 20px

  &__contactIcon
    font-size: 20px
    color: $color-primary

  &__contactText
    font-size: 20px
    font-weight: 500
    color: $color-dark-text
    margin-left: 10px

  &__column
    flex-direction: column
    flex: 1


  &__form
    flex-direction: column
    display: flex

    label
      font-size: 16px
      font-weight: 600
      color: $color-dark-text
      margin-bottom: 10px
      margin-top: 20px

    input, textarea
      border: 1px solid $color-light-text
      border-radius: 8px
      padding: 10px
      margin-bottom: 20px
      font-size: 16px
      color: black
      background-color: transparent
      transition: border-color .5s ease-in-out

      &:focus
        border-color: $color-primary
    
    button[type="submit"]
      background-color: $color-primary
      color: white
      border: none
      padding: 10px 20px
      border-radius: 8px
      font-size: 16px
      font-weight: 600
      cursor: pointer
      transition: background-color .5s ease-in-out

      &:hover
        background-color: $color-secondary

  &__success
    font-weight: 600
    margin-top: 20px
    color: $color-primary
  
  &__error
    font-weight: 600
    margin-top: 20px
    color: salmon

@media (max-width: $layout-breakpoint-small)
  .blockContact
    flex-direction: column
    padding: 0 20px