@import '../variables'

.blockSteps
  flex-direction: column
  align-items: center
  padding: 100px 20px
  overflow: hidden

  &__title
    font-size: 32px
    text-align: left
    font-weight: 500
    line-height: 1.25em

  &__subtitle
    font-size: 12px
    color: $color-text
    letter-spacing: 2.4px
    margin-bottom: 10px
    font-weight: 500

  &__divider
    width: 50px
    height: 1px
    background: black
    margin-bottom: 40px
    margin-top: 20px
  
  &__feature
    align-items: flex-start
    flex-direction: column
    flex: 1
    transition: border-color .5s ease-in-out
    position: relative
    padding-right: 40px
  
  &__icon
    color: $color-primary

  &__featureTitle
    font-size: 18px
    font-weight: 600
    color: $color-secondary
    text-align: center
    line-height: 1.75em
    text-transform: uppercase

  &__featureSubTitle
    font-size: 14px
    color: $color-text
    margin-bottom: 30px
    font-weight: 500

  &__featureNumber
    background-color: $color-primary
    aspect-ratio: 1
    border-radius: 50%
    width: 40px
    justify-content: center
    align-items: center
    position: relative
    margin-bottom: 30px

    p
      color: white
      font-size: 15px
      line-height: 15px
      font-weight: bold
    &::after
      content: ''
      position: absolute
      left: 0px
      width: 100%
      height: 2px
      z-index: -1
      background-color: $color-super-light-text

  &__featureText
    font-size: 15px
    font-weight: 400
    color: $color-text
    line-height: 1.75em
    margin-bottom: 80px

  &__link
    padding: 20px 30px
    border-radius: 40px
    background-color: $color-primary
    color: white
    font-weight: 600
    text-decoration: none

  @media (max-width: $layout-breakpoint-small)
    &
      overflow: visible
    &__columns
      flex-direction: column

    &__feature
      max-width: 400px
    