@import 'variables'


.navbarContainer
  height: $navbar-height
  
.navbar
  background-color: white
  color: rgb(117, 112, 138)
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  height: $navbar-height
  position: fixed
  top: 0
  width: 100%
  z-index: 20
  box-shadow: 0 20px 70px -10px rgba(9, 31, 67, 0.15)
  padding-left: 20px
  padding-right: 20px


  .logo
    display: inline-block
    max-height: calc($navbar-height - 20px)
    max-width: 200px

  .navBar__navContainer
    display: flex
    flex-direction: row
    height: 100%

  nav
    display: flex
    top: $navbar-height
    bottom: 100vh
    left: 0
    right: 0
    background-color: white
    flex-direction: column
    position: fixed
    transition: bottom .3s ease-in-out
    z-index: 2

    .navbar__link
      margin-right: 20px
      position: relative
      height: 100%
      align-items: center
      justify-content: center
      display: flex
      cursor: pointer

      a
        color: $color-text
        text-decoration: none
        padding: 10px 10px
        transition: color 0.2s ease-in-out
        font-weight: 500
        position: relative
        overflow: visible
        height: 100%
        align-items: center
        display: flex

      &:hover
        color: $color-secondary
        a
          color: $color-secondary

        & .navbar__childMenu
          display: flex
    
    .navbar__childMenu
      display: none
      position: absolute
      background-color: #f9f9f9
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
      padding: 12px 16px
      z-index: 1
      top: $navbar-height
      flex-direction: column

      .navbar__childLink
        color: $color-text
        text-decoration: none
        padding: 10px 10px
        font-weight: 500
        margin-right: 20px
        text-align: left

        &:hover
          color: $color-secondary
      

  .hamb
    align-self: center
    cursor: pointer
    padding: 20px 20px

    .hamb-line
      background:$color-text
      display: block
      height: 2px
      position: relative
      width: 24px
    
      &:before, &:after
        background: $color-text
        content: ''
        display: block
        height: 100%
        position: absolute
        width: 100%
        transition: all 0.2s ease-in-out
      
      &:before
        top: -8px
      
      &:after
        bottom: -8px
      
  .side-menu
    &:checked ~ nav
      bottom: 0
    
    &:checked ~ .hamb .hamb-line
      background: transparent
    
    &:checked ~ .hamb .hamb-line:before
      transform: rotate(45deg)
      top: 0
    
    &:checked ~ .hamb .hamb-line:after
      transform: rotate(-45deg)
      bottom: 0
  


  // desktop
  @media (min-width: $layout-breakpoint-small)
    nav
      position: unset
      display: flex
      flex-direction: row
      align-items: center

    .hamb
      display: none

  @media (max-width:  $layout-breakpoint-small)
    nav
      align-items: flex-start

      .navbar__link
        overflow: hidden
        height: unset
        flex-direction: column
        align-items: flex-start

        a
          margin-right: unset
          text-align: right
          overflow: hidden
          height: unset

        .navbar__childMenu
          display: flex
          position: unset
          background: unset
          box-shadow: unset
          align-items: flex-start
          margin-top: -10px