@import ../variables
  
.blockPricing
  background-color: rgb(247, 248, 253)
  padding-top: 50px
  padding-bottom: 50px

  &__columns
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    margin-top: 40px
    flex: 1
  
  &__column
    flex-direction: column
    align-items: center
    flex: 1
    max-width: 350px
    margin: 20px
    background: linear-gradient(135deg, rgb(51, 57, 81), rgb(23, 27, 39))
    color: white

    &.blockPricing__highlight
      background: $color-primary

      .blockPricing__cta
        color: white
        background: $color-secondary
    

  &__title
    color: white
    font-size: 24px
    font-weight: 600
    margin-top: 40px

  &__subtitle
    
    color: white
    opacity: 0.4
    font-size: 16px
    font-weight: 500
    margin-top: 10px
    margin-bottom: 10px
    text-align: center

  &__priceRow
    flex-direction: row
    align-items: center
    margin-top: 20px
    margin-bottom: 10px

  &__price
    font-size: 50px
    font-weight: 700
    margin: 5px
    margin-top: 10px
    margin-bottom: 10px

  &__currency
    font-size: 24px
    font-weight: 600

  &__divider
    width: 100%
    height: 1px
    background: $color-light-text
    margin-top: 20px
    margin-bottom: 20px
    opacity: 0.4

  &__feature
    font-size: 16px
    font-weight: 400
    margin-top: 10px
    margin-bottom: 10px
    text-align: center
  
  &__featureBold
    font-weight: 700
    margin-right: 10px

  &__cta
    margin-top: 20px
    margin-bottom: 30px
    font-size: 16px
    font-weight: 600
    color: $color-secondary
    background: $color-primary
    border-radius: 35px
    padding: 15px 30px
    cursor: pointer
    text-decoration: none