// css reset
*, *:before, *:after
  box-sizing: border-box

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline
  text-rendering: optimizeLegibility
  -webkit-font-smoothing: antialiased
  text-size-adjust: none
  text-align: left

footer, header, nav, section, main
  display: block

body
  line-height: 1

ol, ul
  list-style: none

blockquote, q
  quotes: none

blockquote:before, blockquote:after, q:before, q:after
  content: ''
  content: none

table
  border-collapse: collapse
  border-spacing: 0

input
  -webkit-appearance: none
  border-radius: 0

// shared
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap')
html
  font-family: Poppins, sans-serif

div
  display: flex

.layout
  flex: 1
  flex-direction: column

.pageWidth
  max-width: 1200px
  flex: 1
  margin: 0 auto
  width: 100%
  

// import stylesheets
@import navbar.sass
@import footer.sass
@import post.sass
@import help.sass
@import posts.sass
@import components/markdown.sass
@import components/hero.sass
@import components/hero2.sass
@import components/downloadButtons.sass
@import components/featureBlock.sass
@import components/splitSectionBlock.sass
@import components/partners.sass
@import components/stepsBlock.sass
@import components/reviewsBlock.sass
@import components/functionsWithImage.sass
@import components/faq.sass
@import components/newsItem.sass
@import components/news.sass
@import components/contact.sass
@import components/pricing.sass
@import components/textSection.sass
@import components/team.sass