.blockPartners
  display: flex
  flex-direction: column
  margin: 80px 0

  &__title
    text-align: center
    font-size: 12px
    letter-spacing: 2px
    color: $color-text
    margin-bottom: 20px

  &__box
    flex: 1
    width: 100%
    box-shadow: 0 20px 70px -10px rgba(9, 31, 67, 0.15)
    justify-content: space-evenly
    padding: 40px
    border-radius: 10px
    flex-wrap: wrap
    margin: 0 20px

  &__partner
    opacity: 0.5
    transition: opacity .5s ease-in-out

    &:hover
      opacity: 1

  &__image
    height: 100px
    width: 200px