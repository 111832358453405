@import ./variables
  
.help
  flex-direction: column
  align-items: center
  margin-top: 100px

  &__title
    font-size: 40px
    font-weight: 700
    color: #000000
    margin: 0 20px
    margin-bottom: 40px

  &__items
    flex-direction: row
    align-items: center
    flex-wrap: wrap

  &__item
    display: flex
    border: 1px solid rgb(230, 228, 239)
    border-radius: 8px
    margin: 15px
    padding: 20px
    flex-direction: column
    flex: 1
    transition: border-color .5s ease-in-out
    position: relative
    cursor: pointer
    text-decoration: none
    color: black
    min-width: 40%

    &:hover
      border-color: transparent

    &::after
      content: ''
      box-shadow: 0 20px 70px -10px rgba(9, 31, 67, 0.15)
      opacity: 0
      top: 0
      left: 0
      transition: opacity .5s ease-in-out
      position: absolute
      z-index: -1
      width: 100%
      height: 100%

    &:hover::after
      opacity: 1

  &__itemTitle
    font-size: 18px
    font-weight: 600
    color: #000000
    line-height: 1.75em

  &__itemBody


    
.helpPage
  flex-direction: column
  align-items: center
  padding: 0px 20px
  margin-top: 100px

  &__title
    font-size: 40px
    font-weight: 700
    color: $color-secondary
  
  &__body
    font-size: 16px
    color: $color-text
    text-align: center
    margin-top: 20px

  &__links
    flex-direction: row
    margin-top: 60px
    margin-bottom: 100px
    flex-wrap: wrap
    justify-content: center

  &__link
    font-size: 16px
    color: black
    text-decoration: none
    cursor: pointer
    min-width: 40%
    margin-bottom: 10px

    &:hover
      text-decoration: underline
      color: $color-secondary

  &__posts
    flex-direction: column

  &__post
    scroll-margin-top: 200px
    margin-bottom: 100px

  &__postTitle
    font-size: 30px
    font-weight: 700
    color: $color-secondary
    margin-bottom: 20px

  &__postImage
    height: 400px
    width: 200px
    overflow: hidden

@media (max-width: $layout-breakpoint-small)
  .help
    &__items
      flex-direction: column

    &__item
      min-width: 100%
      margin: 0
      margin-bottom: 20px

  .helpPage
    
    &__links
      flex-direction: column

    &__link
      margin: 10px 0

    &__posts
      flex-direction: column
      margin: 0 20px

    &__post
      margin-bottom: 100px

    &__postTitle
      font-size: 24px

    &__postImage
      height: 300px
      width: 150px