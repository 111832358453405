@import ../variables
  
.blockFunctionsWithImage
  flex-direction: column
  align-items: center
  margin-top: 100px
  margin-bottom: 100px

  &__container
    background-image: linear-gradient(to top, rgb(247, 248, 253), transparent)

  &__title
    font-size: 36px
    text-align: left
    line-height: 1.25em
    color: $color-secondary

  &__subtitle
    font-size: 16px
    color: $color-text
    margin-bottom: 10px
    margin-top: 20px
    font-weight: 500

  &__columns
    flex-direction: row
    margin-top: 40px

  &__column
    flex-direction: column
    flex: 1
    padding: 20px
    justify-content: center

  &__image
    max-height: 800px
    max-width: 30vw
    margin: 0 auto

  &__function
    flex-direction: column
    margin: 30px 0

  &__functionTitle
    font-size: 20px
    font-weight: 600
    margin-bottom: 10px
    color: $color-secondary
  
  &__functionBody
    font-size: 15px
    font-weight: 300
    color: $color-text
    line-height: 1.75em

  @media (max-width: $layout-breakpoint-small)
    &__columns
      flex-direction: column

      .blockSplitSection__image
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        border-top-left-radius: 0
        border-bottom-left-radius: 0

    &__column
      max-width: unset