@import ../variables
  
.blockHero
  text-align: center
  position: relative
  height: calc( 100vh - $navbar-height )
  width: 100%
  display: flex

  &__title
    font-size: 50px
    color: #fff
    text-align: left
    font-weight: 500
    line-height: 1.25em

  &__subtitle
    margin-top: 40px
    font-size: 17px
    color: #fff
    font-weight: 400
    line-height: 1.75em
    margin-bottom: 40px

  &__image
    bottom: 0
    width: 100%
    position: absolute
    top: 0
    left: 0

    &Overlay
      bottom: 0
      width: 100%
      background: linear-gradient(to right, rgba(21,20,36,0.6), rgba(21,20,36,0))
      position: absolute
      top: 0
      left: 0

  &__columns
    display: flex
    flex-direction: row
    flex: 1

  &__column
    flex: 1
    flex-direction: column
    justify-content: center
    align-items: center
    max-width: 540px
    padding: 20px

@media (max-width: $layout-breakpoint-small)
  .blockHero__columns
    flex-direction: column

