.blockSplitSection
  margin: 40px 0

  &__columns___reverse
    flex-direction: row-reverse

    .blockSplitSection__image
      border-top-left-radius: 50px
      border-bottom-left-radius: 50px
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      overflow: hidden

  &__column
    flex: 1
    flex-direction: column

  &__image
    border-top-right-radius: 50px
    border-bottom-right-radius: 50px
    overflow: hidden

  &__textColumn
    padding: 40px
    max-width: 600px
    flex-direction: column
    align-self: center

  &__title
    color: $color-secondary
    font-size: 32px
    font-weight: 500
    margin-top: 10px
    margin-bottom: 30px
  
  &__subtitle
    letter-spacing: 2px
    color: $color-text
    font-weight: 600
    font-size: 12px

  &__body
    font-size: 15px
    font-weight: 400
    color: $color-text
    line-height: 1.75em


  @media (max-width: $layout-breakpoint-small)
    &__columns
      flex-direction: column

      .blockSplitSection__image
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        border-top-left-radius: 0
        border-bottom-left-radius: 0

    &__column
      max-width: unset
