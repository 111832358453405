.blockNews
  margin-top: 30px
  margin-bottom: 30px

  &__list
    flex-direction: row
    flex:1

  &__listItem
    max-width: 33%

@media (max-width: $layout-breakpoint-small)
  .blockNews
    margin-top: 0
    margin-bottom: 0

    &__list
      flex-direction: column

    &__listItem
      max-width: 100%