.posts
  flex-direction: column
  align-items: center
  margin-top: 100px

  &__title
    font-size: 40px
    font-weight: 700
    color: #000000
    margin-bottom: 20px
  
  &__items
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start

  &__item
    max-width: 33%

@media (max-width: $layout-breakpoint-small)
  .posts
    &__items
      flex-direction: column
      align-items: center

    &__item
      max-width: 100%