@import '../variables'

.blockFeatures
  flex-direction: column
  align-items: center
  padding: 60px 0

  &__title
    font-size: 32px
    text-align: left
    font-weight: 500
    line-height: 1.25em

  &__subtitle
    font-size: 12px
    color: $color-text
    letter-spacing: 2.4px
    margin-bottom: 10px
    font-weight: 500

  &__divider
    width: 50px
    height: 1px
    background: black
    margin-bottom: 40px
    margin-top: 20px
  
  &__feature
    justify-content: center
    border: 1px solid rgb(230, 228, 239)
    border-radius: 8px
    margin: 15px
    padding: 40px 40px
    align-items: center
    flex-direction: column
    flex: 1
    transition: border-color .5s ease-in-out
    position: relative
    aspect-ratio: 1

    &:hover
      border-color: transparent

    &::after
      content: ''
      box-shadow: 0 20px 70px -10px rgba(9, 31, 67, 0.15)
      opacity: 0
      transition: opacity .5s ease-in-out
      position: absolute
      z-index: -1
      width: 100%
      height: 100%

    &:hover::after
      opacity: 1
  
  &__icon
    color: $color-primary 
    margin-bottom: 20px

  &__featureTitle
    font-size: 18px
    font-weight: 600
    margin-bottom: 20px
    color: $color-secondary
    text-align: center
    line-height: 1.75em

  &__featureText
    font-size: 15px
    font-weight: 400
    color: $color-text
    text-align: center
    line-height: 1.75em

  @media (max-width: $layout-breakpoint-small)
    &__columns
      flex-direction: column

    &__feature
      max-width: 400px
    