@import 'variables'



.footer
  flex-direction: column
  padding-top: 20px

  &__image
    flex-direction: column
    object-fit: cover
    position: relative
    background-repeat: no-repeat
    background-position: bottom
    background-size: cover

  &__imageOverlay
    bottom: 0
    width: 100%
    background: linear-gradient(to top, rgba(247, 248, 253, 0.8), rgb(247, 248, 253))
    position: absolute
    top: 0
    left: 0

  &__columns
    display: flex
    flex-direction: row
    flex: 1

  .logo
    display: inline-block
    max-height: calc($navbar-height - 20px)
    max-width: 200px

  &__column
    flex: 1
    flex-direction: column
    align-items: flex-start
    max-width: 540px
    padding: 20px

  &__email
    font-size: 15px
    color: black
    font-weight: 400
    margin-top: 20px

  &__link
    color: rgb(168, 177, 191)
    text-decoration: none
    margin: 8px 0px
    transition: color 0.2s ease-in-out
    font-weight: 500
    text-align: right
    margin-right: 20px

    &:hover
      color: rgb(72, 78, 87)

  &__aboutTitle
    font-size: 15px
    color: rgb(9, 54, 54)
    font-weight: 600
    margin-bottom: 15px

  &__divider
    height: 1px
    width: 100%
    background-color: rgb(230, 228, 239)
    margin: 40px 0

  &__ccColumns
    display: flex
    flex-direction: row
    flex: 1
    justify-content: space-between

  &__ccText
    margin: 0 20px
    margin-bottom: 40px

  @media (max-width: $layout-breakpoint-small)
    &__columns
      flex-direction: column

  p
    font-size: 15px
    font-weight: 400
    color: rgb(117, 112, 138)
    line-height: 1.75em

