// breakpoints
$layout-breakpoint-small: 960px

// colors
// $color-primary: rgb(72, 78, 87)
$color-primary: #1db954
$color-background: white
$color-secondary: rgb(9, 54, 54)
$color-dark-text: rgb(72, 78, 87)
$color-text: rgb(117, 112, 138)
$color-light-text: #a09ea8
$color-super-light-text: rgb(198, 196, 206)

// sizes
$navbar-height: 80px
