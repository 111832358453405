@import '../variables'

.blockTeam
  flex-direction: column

  &__title
    font-size: 32px
    text-align: left
    font-weight: 500
    line-height: 1.25em

  &__columns
    flex-direction: row
    flex-wrap: wrap
    justify-content: center

  &__column
    flex-direction: column
    align-items: center
    padding: 20px
    flex: 1
    max-width: 300px

  &__image
    aspect-ratio: 1
    border-radius: 50%
    overflow: hidden
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    width: 100px
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2)

  &__name
    font-size: 18px
    font-weight: 600
    color: $color-secondary
    text-align: center
    line-height: 1.75em
    text-transform: uppercase
    margin-top: 10px

  &__teamTitle
    font-size: 14px
    color: $color-text
    font-weight: 500
    text-align: center

  &__description
    font-size: 15px
    color: $color-text
    line-height: 1.5em
    font-weight: 400
    text-align: center
    margin-top: 15px

  &__email
    font-size: 14px
    color: $color-text
    font-weight: 500
    text-align: center
    margin-top: 20px

  &__phone
    font-size: 14px
    color: $color-text
    font-weight: 500
    text-align: center
    margin-top: 10px

