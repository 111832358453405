@import ../variables.sass

.newsItem
  flex-direction: column
  box-shadow: 0 20px 70px -10px rgba(9, 31, 67, 0.15)
  margin: 20px
  cursor: pointer
  transition: transform .3s ease-in-out
  border-radius: 5px
  text-decoration: none

  &:hover
    transform: translateY(-10px)

    .newsItem__image
      img
        transform: scale(1.1)

  &__image
    overflow: hidden
    height: 200px

    img
      object-fit: cover !important
      transition: transform .3s ease-in-out

  &__title
    font-size: 20px
    font-weight: 600
    color: $color-secondary
    padding: 20px 20px
  
  &__body
    padding: 0 20px
    font-size: 14px
    color: $color-text
    line-height: 1.75em

  &__author
    font-size: 12px
    color: $color-text
    font-weight: 600
    padding: 20px 20px