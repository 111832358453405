@import ../variables
  
.markdown
  color: $color-text
  flex-direction: column

  ul,ol
    list-style-type: initial
    margin-bottom: 15px

    li
      margin-left: 30px
      line-height: 1.5em
      margin-bottom: 10px

      // &:first-letter
      //   text-transform: uppercase

  ol
    list-style-type: circle

  strong
    font-weight: 600
    color: $color-secondary

  em
    font-style: italic

  blockquote
    padding-left: 10px
    border-left: 3px solid $color-secondary
    margin-bottom: 20px
    p
      margin-bottom: 0

  h2
    font-size: 20px
    font-weight: 700
    color: $color-secondary
    margin-bottom: 10px
    margin-top: 20px
  p
    margin-bottom: 10px
    line-height: 1.5em
  
  a
    text-decoration: underline