@import ../variables.sass
  
.blockReviews
  background-image: linear-gradient(135deg, rgb(32, 25, 60), rgb(15, 10, 34))
  flex: 1
  padding: 100px 0
  overflow: hidden

  &__container
    position: relative
    flex-direction: column

  &__title
    color: $color-primary
    font-size: 32px
    font-weight: 500
    margin-top: 10px
    margin-bottom: 100px
    text-align: center
  
  &__subtitle
    letter-spacing: 2px
    color: $color-super-light-text
    font-weight: 600
    font-size: 12px
    text-align: center
    margin-botom: 50px

  &__chevronLeft
    position: absolute
    left: 29%
    top: 50%
    transform: translateY(-50%)
    cursor: pointer
    color: $color-light-text
    font-size: 30px
    transition: color .5s ease-in-out

    &:hover
      color: $color-primary

  &__chevronRight
    position: absolute
    right: 29%
    top: 50%
    transform: translateY(-50%)
    cursor: pointer
    color: $color-light-text
    font-size: 30px
    transition: color .5s ease-in-out

    &:hover
      color: $color-primary

  &__card
    background-color: rgba(255, 255, 255, 0.1)
    
    border-radius: 20px
    padding: 40px
    flex-direction: column
    transition: background-color .5s ease-in-out

  &__review
    position: absolute
    transition: left 0.5s ease-in-out
    transition-property: left, transform, background-color
    width: 25%
    transform: scale(0.8)
    opacity: 0
    flex-direction: column

    &.prevCard
      left: 0
      opacity: 1

    &.nextCard
      left: 100%
      transform: translateX(-100%)
      opacity: 1
    
    &.activeCard
      left: 50%
      transform: scale3d(0.8, 0.8, 1)
      transform: translateX(-50%)
      opacity: 1

      & .blockReviews__card
        background-color: $color-primary

    &.secondNextCard
      left: 200%
      transform: translateX(-200%)
      opacity: 1
    
    &.secondPrevCard
      left: -100%
      transform: translateX(100%)
      opacity: 1

    & p
      color: white
      text-align: center
      line-height: 1.3em

  &__cardTitle
    font-size: 18px
    font-weight: 500
    color: $color-secondary
    text-align: center
    line-height: 1.75em
    margin-top: 40px


  @media (max-width: $layout-breakpoint-small)
    &__review
      width: 60%

      &.prevCard
        left: -60%

      &.nextCard
        left: 160%
        transform: translateX(-100%)

      &.secondNextCard
        left: 300%
        transform: translateX(-200%)
      
      &.secondPrevCard
        left: -200%
        transform: translateX(100%)

    &__chevronRight
      right: 10%
    
    &__chevronLeft
      left: 10%

      