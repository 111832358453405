@import ../variables
  
.blockHero2
  text-align: center
  position: relative
  width: 100%
  display: flex
  padding-top: 50px
  padding-bottom: 50px

  &__title
    font-size: 48px
    color: #fff
    text-align: left
    font-weight: 700
    line-height: 1.25em
    color: $color-secondary

  &__subtitle
    margin-top: 40px
    margin-bottom: 40px
    font-size: 17px
    color: $color-secondary
    font-weight: 400
    line-height: 1.75em

  &__image
    max-height: 600px

  &__imageOverlay
    bottom: 0
    width: 100%
    background: linear-gradient(45deg, rgb(179, 252, 208), rgb(29, 185, 84))
    position: absolute
    top: 0
    left: 0

  &__columns
    display: flex
    flex-direction: row
    flex: 1

  &__column
    flex: 1
    flex-direction: column
    justify-content: center
    align-items: center
    max-width: 540px
    padding: 20px

    &___center
      align-items: center
      justify-content: center

  @media (max-width: $layout-breakpoint-small)
    &__columns
      flex-direction: column

    &__column
      max-width: unset
      align-items: flex-start